import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import credibilityBounce from "../../../animations/Amazon/icon-bounce-credibility.json";
import calendarAnimation from "../../../animations/Amazon/intraday-sov-animation.json";
import { GatsbyImage } from "gatsby-plugin-image";

interface Intraday {
  title: any;
  description: string;
  gradientPosition: number;
  image: any;
  lottieJson: any;
}

const Intraday: React.FC<Intraday> = props => {
  const { title, description, gradientPosition, image, lottieJson } = props;

  const animationContainer = createRef<HTMLDivElement>();
  const calendarContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: credibilityBounce
    });

    const calendar = lottie.loadAnimation({
      container: calendarContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(lottieJson != undefined && lottieJson)
    });
    return () => {
      anim.destroy();
      calendar.destroy();
    };
  }, []);

  return (
    <div className="grid grid-cols-2 space-x-2 py-12 relative items-center">
      <div className="col-span-2 relative z-10 lg:col-span-1">
        <div
          className="w-14 h-14 shadow-2xl rounded-full mb-8"
          ref={animationContainer}
        ></div>
        <GradientTitle
          className="text-left font-bold text-5xl"
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h2}
          title={title}
          lineBreak={true}
        />
        <Paragraph>{description}</Paragraph>
      </div>
      <div className="col-span-2 flex justify-end relative z-10 ml-auto lg:col-span-1">
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Perpetua editorial wireframe."
          className="w-full md:w-3/4 mt-40 md:ml-12 md:mt-7 rounded-tl-large rounded-br-large"
        />
        <div
          className="absolute max-w-[200px] md:max-w-md lg:max-w-xs  xl:max-w-[400px] top-1/4 left-0"
          ref={calendarContainer}
        ></div>
      </div>
    </div>
  );
};

export default Intraday;
