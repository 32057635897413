import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import credibilityBounce from "../../../animations/Amazon/icon-bounce-credibility.json";
import comfyShoeSwap from "../../../animations/Amazon/comfy-shoe-swap.json";

interface Credibility {
  title: any;
  description: string;
  gradientPosition: number;
  image: any;
  lottieJson: any;
}

const Credibility: React.FC<Credibility> = props => {
  const { title, description, gradientPosition, image, lottieJson } = props;

  const animationContainer = createRef<HTMLDivElement>();
  const shoeContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: credibilityBounce
    });

    const shoes = lottie.loadAnimation({
      container: shoeContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(lottieJson != undefined && lottieJson)
    });
    return () => {
      anim.destroy();
      shoes.destroy();
    };
  }, []);

  return (
    <div className="grid grid-cols-2 space-x-2 py-12 relative items-center">
      <div className="col-span-2 relative z-10 lg:col-span-1">
        <div
          className="w-14 h-14 shadow-2xl rounded-full mb-8"
          ref={animationContainer}
        ></div>
        <GradientTitle
          className="text-left font-semibold w-3/4 leading-initial mb-7"
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h4}
          title={title}
          lineBreak={true}
        />
        <Paragraph>{description}</Paragraph>
      </div>
      <div className="col-span-2 flex h-full items-center relative z-10 lg:col-span-1">
        <GatsbyImage
          image={image}
          alt="Perpetua editorial wireframe."
          className="w-full mt-40 md:ml-12 md:mt-0"
        />
        <div
          className="absolute shadow-xl left-1/2 -translate-x-1/2 -translate-y-10 w-1/2 sm:-translate-x-2 sm:-translate-y-1/4 sm:w-1/4 sm:top-1/2 sm:left-8"
          ref={shoeContainer}
        ></div>
      </div>
    </div>
  );
};

export default Credibility;
