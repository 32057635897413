import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import { graphql, useStaticQuery } from "gatsby";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { useSegment } from "../../../utils/analytics";
import { Name, Type, Category } from "../../../utils/analytics/constants";
import BackgroundImage from "gatsby-background-image";
import "../BMSection/benchmarker.scss";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import GradientTitle from "../../GradientTitle";
import { nav } from "../../../utils/navigation";

interface BMProps {
  title: [];
  description: string;
  gradientPosition: number;
  image: any;
  features: any;
  cta: any;
}
const BMSection: React.FC<BMProps> = props => {
  const { title, description, image, gradientPosition, features, cta } = props;
  const { helpers } = useSegment();

  const bmImage = useStaticQuery(graphql`
    query {
      grid: file(relativePath: { eq: "index/bm-grid.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const getBgImage = getImage(bmImage.bm);
  const backgroundImage = convertToBgImage(getBgImage);

  return (
    <div className="px-6 sm:px-4 max-w-8xl mx-auto">
      <div className="text-center pt-20 pb-20 md:pt-20 md:pb-24">
        <div className="w-full mx-auto lg:max-w-4xl">
          <GradientTitle
            gradientPosition={gradientPosition}
            color="purple"
            variant={HeaderVariant.h2}
            title={title}
            lineBreak={true}
          />
          <Paragraph className="text-2xl font-semibold">
            {description}
          </Paragraph>
        </div>
        <div className="bm-section pt-12 pb-20 md:pt-20">
          <div className="relative flex justify-center items-center">
            <div className="absolute z-0 flex justify-center items-center w-full">
              <GatsbyImage
                image={bmImage?.grid?.childImageSharp?.gatsbyImageData}
                alt="Perpetua Benchmarker"
                className="w-full"
              />
            </div>
            <GatsbyImage
              image={image}
              alt="Get started with benchmarker"
              className="w-full"
            />
          </div>
          <div className="bullet-points flex flex-col items-center">
            {features &&
              features?.map((card, i) => {
                return (
                  <div
                    key={i}
                    className={
                      "bullet text-center lg:text-left bg-blue-3-light px-6 py-3 rounded-lg font-semibold text-base shadow text-indigo-1 " +
                      card?.className
                    }
                  >
                    {card?.text}
                    <hr></hr>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="m-auto max-w-min">
          <Button
            id="amazon-advertising-software-bm-cta"
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            text={cta?.text}
            onClick={() => {
              helpers
                .delayTrack(Name.Cta, {
                  category: Category.ButtonClick,
                  type: Type.Button,
                  text: cta?.text,
                  page_url: location.href,
                  destination: cta?.route,
                  location: "Homepage BM"
                })
                .then(() => {
                  nav(cta?.route);
                });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BMSection;
