import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import Hero from "../components/AmazonPage/Hero";
import Crocs from "../components/Brands/BrandSvgs/crocs.svg";
import HeroCosmetics from "../components/Brands/BrandSvgs/hero-cosmetics.svg";
import Huggies from "../components/Brands/BrandSvgs/huggies.svg";
import FourSigmatic from "../components/Brands/BrandSvgs/foursigmatic.svg";
import Incrementum from "../components/Brands/BrandSvgs/incrementum.svg";
import Cartograph from "../components/Brands/BrandSvgs/cartograph.svg";
import Kleenex from "../components/Brands/BrandSvgs/kleenex.svg";
import Brands, { BrandObject } from "../components/Brands";
import Section from "../containers/Section";
import GoalSection from "../components/AmazonPage/GoalSection";
import BidOptimization from "../components/AmazonPage/BidOptimization";
import BottomCTA from "../components/BottomCta";
import { useSegment, trackView } from "../utils/analytics";
import {
  Category,
  Location,
  Name,
  Type,
  CTA_ID
} from "../utils/analytics/constants";
import { nav } from "../utils/navigation";
import Strategy from "../components/AmazonPage/Strategy";
import Tactics from "../components/AmazonPage/Tactics/index";
import Credibility from "../components/AmazonPage/Credibility/index";
import Intraday from "../components/AmazonPage/Intraday";
import BMSection from "../components/AmazonPage/BMSection";

interface AmazonAdvertisingProps {
  data: any;
}

const AmazonAdvertisingPage: React.FC<AmazonAdvertisingProps> = ({ data }) => {
  const { segment, helpers } = useSegment();

  const pageData = data?.pageInfo?.edges[0]?.node;

  const brandsList: BrandObject[] = [
    { src: <Crocs />, filter: true },
    { src: <Kleenex />, filter: true },
    { src: <Huggies />, filter: true },
    { src: <FourSigmatic />, filter: true },
    { src: <HeroCosmetics />, filter: true },
    { src: <Incrementum />, filter: true },
    { src: <Cartograph />, filter: true }
  ];

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <Section className="overflow-hidden">
        <Hero
          h1Title={pageData?.h1Title}
          title={pageData?.heroMainTitle}
          description={pageData?.heroDescription?.heroDescription}
          image={pageData?.heroImage?.gatsbyImageData}
          cta={pageData?.heroCta}
          secondaryCta={pageData?.secondaryHeroCta}
          gradientPosition={pageData?.heroMainTitleGradientPosition}
        />
      </Section>
      <div className="max-w-6xl py-7 sm:py-14 mx-auto">
        <Brands brandsList={brandsList} />
      </div>
      <Section className="py-7 sm:py-14">
        <GoalSection
          image={data?.productImage}
          title={pageData?.goalsMainTitle}
          description={pageData?.goalsDescription?.goalsDescription}
          goalJson={
            pageData?.childContentfulAmazonAdvertisingGoalSelectorsJsonNode
          }
          gradientPosition={pageData?.goalsMainTitleGradientPosition}
        />
      </Section>
      <Section className="mt-7 my-14 max-w-8xl px-8 relative">
        {/* On Bid */}
        <BidOptimization
          title={pageData?.biddingMainTitle}
          description={pageData?.biddingDescription?.biddingDescription}
          gradientPosition={pageData?.biddingMainTitleGradientPosition}
          image={pageData?.optimizationImage?.gatsbyImageData}
        />
        <div className="bg-gradient-to-r from-orange-3 to-orange-2 rounded-bl-large rounded-tr-large absolute top-0 bottom-0 md:bottom-1/3 lg:bottom-0 left-0 right-0 max-w-8xl sm:right-4"></div>
      </Section>
      <Section>
        <Intraday
          title={pageData?.intradayMainTitle}
          description={pageData?.intradayDescription?.intradayDescription}
          gradientPosition={pageData?.intradayMainTitleGradientPosition}
          image={data?.managerWorking}
          lottieJson={pageData?.intradayLottieJson?.internal?.content}
        />
      </Section>
      <div className="bg-gradient-to-b from-white to-gray-5 py-14">
        <Section>
          <Strategy
            title={pageData?.strategyMainTitle}
            gradientPosition={pageData?.strategyMainTitleGradientPosition}
            tabs={pageData?.strategyTabObject}
            cta={pageData?.strategyCta}
          />
        </Section>
      </div>
      <BMSection
        title={pageData?.bmSectionTitle}
        description={pageData?.bmSectionDescription?.bmSectionDescription}
        gradientPosition={pageData?.bmTitleGradientPosition}
        image={pageData?.bmImage?.gatsbyImageData}
        features={pageData?.bmFeatures}
        cta={pageData?.bmSectionCta}
      />
      <Section className="py-7 sm:py-14">
        <Tactics
          title={pageData?.tacticsMainTitle}
          description={pageData?.tacticsDescription?.tacticsDescription}
          gradientPosition={pageData?.tacticsMainTitleGradientPosition}
          image={data?.displayAdvertising}
          tactics={pageData?.tacticsCtas}
        />
      </Section>
      <Section>
        <Credibility
          title={pageData?.credibilityMainTitle}
          description={pageData?.credibilityDescription?.credibilityDescription}
          gradientPosition={pageData?.credibilityMainTitleGradientPosition}
          image={pageData?.credibilityImage?.gatsbyImageData}
          lottieJson={pageData?.credibilityLottieFile?.internal?.content}
        />
      </Section>
      <div className="py-7 sm:pt-14">
        <BottomCTA
          primaryButtonId={pageData?.bottomCta?.primaryButtonId}
          secondaryButtonId={
            pageData?.bottomCta?.primaryButsecondaryButtonIdonId
          }
          title={pageData?.bottomCta?.title}
          subtitle={pageData?.bottomCta?.subtitle}
          primaryButtonText={pageData?.bottomCta?.primaryButtonText}
          secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
          primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
          secondaryButtonClassName={
            pageData?.bottomCta?.secondaryButtonClassName
          }
          primaryButtonOnClick={() => {
            helpers
              .delayTrack(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: pageData?.bottomCta?.primaryButtonText,
                page_url: window?.location.href,
                destination: pageData?.bottomCta?.primaryButtonRoute,
                location: Location.BottomCta
              })
              .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
          }}
          secondaryButtonOnClick={() => {
            trackView(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.secondaryButtonText,
              page_url: window?.location.href,
              destination: pageData?.bottomCta?.secondaryButtonRoute,
              location: Location.BottomCta
            });
            nav(pageData?.bottomCta?.secondaryButtonRoute);
          }}
        />
      </div>
    </Layout>
  );
};

export default AmazonAdvertisingPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageInfo: allContentfulAmazonAdvertising(
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          childContentfulAmazonAdvertisingGoalSelectorsJsonNode {
            cta
            name
            options {
              inputs {
                input
                title
              }
              keywords
              name
            }
          }
          seoSettings {
            metadataTitle
            metadataDescription
            openGraphImage {
              gatsbyImageData
              url
            }
            robots
          }
          goalsDescription {
            goalsDescription
          }
          goalsMainTitle
          goalsMainTitleGradientPosition
          heroCta {
            text
            route
          }
          secondaryHeroCta {
            route
            text
          }
          heroDescription {
            heroDescription
          }
          heroImage {
            gatsbyImageData
            url
          }
          heroInputPlaceholder
          heroMainTitle
          heroMainTitleGradientPosition
          hiddenContentTitle
          biddingMainTitle
          biddingMainTitleGradientPosition
          biddingDescription {
            biddingDescription
          }
          optimizationImage {
            gatsbyImageData
            url
          }
          strategyMainTitle
          strategyMainTitleGradientPosition
          strategyTabObject {
            title
            selector
            newTag
            description {
              description
            }
            cta
            animationJson {
              internal {
                content
              }
            }
          }
          tacticsMainTitle
          tacticsMainTitleGradientPosition
          tacticsDescription {
            tacticsDescription
          }
          tacticsCtas {
            text
            route
          }
          credibilityMainTitle
          credibilityMainTitleGradientPosition
          credibilityDescription {
            credibilityDescription
          }
          credibilityImage {
            gatsbyImageData
            url
          }
          credibilityLottieFile {
            internal {
              content
            }
          }
          intradayMainTitle
          intradayMainTitleGradientPosition
          intradayDescription {
            intradayDescription
          }
          intradayLottieJson {
            internal {
              content
            }
          }
          bmSectionTitle
          bmTitleGradientPosition
          bmSectionDescription {
            bmSectionDescription
          }
          bmImage {
            gatsbyImageData(width: 628, height: 409, layout: FIXED)
            url
          }
          bmFeatures {
            text
            className
          }
          bmSectionCta {
            text
            route
          }
          bottomCta {
            title
            subtitle
            primaryButtonId
            secondaryButtonId
            primaryButtonText
            secondaryButtonText
            primaryButtonRoute
            secondaryButtonRoute
            primaryButtonClassName
            secondaryButtonClassName
          }
        }
      }
    }
    productImage: file(relativePath: { eq: "index/product-list.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    alwaysOnImage: file(
      relativePath: { eq: "amazon/always-on-optimization.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    managerWorking: file(relativePath: { eq: "amazon/manager-working.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    displayAdvertising: file(
      relativePath: { eq: "amazon/display-advertising.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    amazonEditorial: file(
      relativePath: { eq: "amazon/amazon-editorial-wire.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
