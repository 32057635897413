import React from "react";
import Confetti from "react-confetti";
import Section from "../../../containers/Section";
import { useWindowSize } from "../../../hooks/useWindowSize";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import SelectableButton from "../../SelectableButton";
import GoalConfigPanel from "./GoalConfigPanel";
import { GatsbyImage } from "gatsby-plugin-image";

interface GoalSectionProps {
  title: [];
  description: string;
  goalJson: any;
  image: any;
  gradientPosition: number;
}

const GoalSection: React.FC<GoalSectionProps> = props => {
  const { title, description, goalJson, image, gradientPosition } = props;
  const [showConfetti, setShowConfetti] = React.useState(false);

  const [activeWindow, setActiveWindow] = React.useState(
    goalJson?.length > 0 ? goalJson[0] : [goalJson][0]
  );

  const launchGoal = () => {
    setTimeout(() => {
      setShowConfetti(true);
    }, 500);
  };

  const size = useWindowSize();

  return (
    <Section>
      <div className="flex flex-col-reverse lg:flex-row items-center">
        <div className="relative flex items-center justify-start lg:w-1/2">
          <GatsbyImage
            image={image?.childImageSharp?.gatsbyImageData}
            alt="product list"
            className="w-72 relative -left-10 md:w-96 z-0 my-6 lg:my-0"
          />
          <div className="absolute -right-20 md:right-3 lg:right-16 scale-75 md:scale-100">
            <GoalConfigPanel variant={activeWindow} onClick={launchGoal} />
          </div>
          {showConfetti && (
            <div className="fixed top-0 left-0 w-full h-screen">
              <Confetti
                colors={["#FF9900", "#c2730d", "#C2410C", "#FEF08A"]}
                style={{ position: "fixed" }}
                width={size.width}
                height={size.height}
                run={showConfetti}
                recycle={false}
                numberOfPieces={1000}
                onConfettiComplete={() => setShowConfetti(false)}
              />
            </div>
          )}
        </div>
        <div className="text-center lg:text-left lg:w-1/2">
          <GradientTitle
            className="text-center lg:text-left block"
            gradientPosition={gradientPosition}
            color="blue"
            variant={HeaderVariant.h2}
            title={title}
            lineBreak={true}
          />
          <Paragraph className="text-2xl">{description}</Paragraph>
          <div className="flex flex-wrap items-center justify-center space-x-4 mb-8 md:mb-20 lg:mb-11 lg:justify-start">
            {[goalJson].length > 1 &&
              [goalJson].map((item, i) => (
                <SelectableButton
                  className="mt-4"
                  key={`${item.name}-${i}`}
                  text={item.name}
                  active={activewindow?.name === item.name}
                  onClick={() => {
                    setActiveWindow(goalJson[i]);
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GoalSection;
