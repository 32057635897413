import React from "react";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import { GatsbyImage } from "gatsby-plugin-image";

interface BidOptimization {
  title: [];
  description: string;
  gradientPosition: number;
  image: any;
}

const BidOptimization: React.FC<BidOptimization> = props => {
  const { title, description, gradientPosition, image } = props;

  return (
    <div className="grid grid-cols-2 pt-12 sm:px-12 relative items-center md:mb-0 md:py-12 md:space-x-2">
      <div className="col-span-2 relative z-10 lg:pl-28 lg:col-span-1">
        <GradientTitle
          className="text-left w-full sm:w-1/2 md:w-full"
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h2}
          title={title}
          lineBreak={false}
        />
        <Paragraph>{description}</Paragraph>
      </div>
      <div className="relative -bottom-12 col-span-2 flex items-center z-10 lg:bottom-0 lg:mt-4 lg:mb-0 lg:col-span-1">
        <GatsbyImage
          image={image}
          alt="Always on bid optimization"
          className="w-full absolute right-0 scale-105 md:scale-100"
        />
      </div>
    </div>
  );
};

export default BidOptimization;
