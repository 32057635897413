import cn from "classnames";
import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../../../Button";
import Edit from "../../../../images/amazon/edit.svg";

interface GoalConfigPanelProps {
  variant: any;
  onClick: () => void;
}
const GoalConfigPanel: React.FC<GoalConfigPanelProps> = props => {
  const { variant, onClick } = props;

  return (
    <>
      <div
        className="bg-white shadow rounded-2xl border border-gray-4-light p-6 space-y-6 transition-all"
        style={{
          minWidth: "344px"
        }}
      >
        {variant && (
          <>
            <div>
              <div className="flex justify-between w-full flex-col">
                {variant?.options?.map(item => (
                  <div className="mt-3 first:mt-0" key={item.key}>
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-gray-2-dark">
                        {item.name}
                      </p>
                      {item.keywords && (
                        <p className="text-gray-1">
                          {item.keywords}
                          <Edit className="inline-block w-3 pb-1 ml-2" />
                        </p>
                      )}
                    </div>
                    <div className="flex justify-between">
                      {item?.inputs?.map(input => (
                        <div
                          key={input.key}
                          className="inline-block mb-4 last:mb-0"
                        >
                          <p className="text-base font-medium text-gray-2-light mb-2">
                            {input.title}
                          </p>
                          <div
                            className={
                              "w-35 border border-gray-3-light rounded-lg py-2 px-3"
                            }
                          >
                            <span>{input.input}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                text={variant.cta}
                onClick={onClick}
                className="w-full mt-4"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GoalConfigPanel;
