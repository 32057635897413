// Lottie animations
import lottie from "lottie-web";

// animation container should include animation.current
// animation should be animation.json.internal.content

// HOW TO USE:

// const animationContainer = createRef<HTMLDivElement>();

// useEffect(() => {
//   const anim = runAnimation(animationContainer.current, currentSelection?.animationJson?.internal?.content, true);
//   console.log(anim);
//   return () => {
//     anim.destroy();
//   };
// }, [currentSelection]);

export const runAnimation = (animationContainer, animation, loop = true) => {
  return lottie.loadAnimation({
    container: animationContainer,
    renderer: "svg",
    loop: loop,
    autoplay: true,
    animationData: animation && animation,
    rendererSettings: {
      filterSize: {
        width: "200%",
        height: "200%",
        x: "-50%",
        y: "0%"
      }
    }
  });
};
