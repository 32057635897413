import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import { Link } from "gatsby";
import amplify from "../../../animations/Amazon/icon-bounce-amplify.json";
import RightArrow from "../../../images/right-arrow.svg";
import { GatsbyImage } from "gatsby-plugin-image";

interface Tactics {
  title: any;
  description: string;
  gradientPosition: number;
  image: any;
  tactics: any;
}

const Tactics: React.FC<Tactics> = props => {
  const { title, description, gradientPosition, image, tactics } = props;

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: amplify
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);
  return (
    <div className="grid grid-cols-2 space-x-2 py-12 relative items-center">
      <div className="order-last mt-12 col-span-2 flex items-center relative z-10 lg:col-span-1 md:mt-0 md:order-first">
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Always on bid optimization"
          className="w-full absolute right-0"
        />
      </div>
      <div className="col-span-2 relative z-10 lg:col-span-1">
        <div
          className="w-14 h-14 shadow-2xl rounded-full mb-8"
          ref={animationContainer}
        ></div>
        <GradientTitle
          className="text-left font-semibold w-3/4 leading-initial mb-7"
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h4}
          title={title}
          lineBreak={true}
        />
        <Paragraph>{description}</Paragraph>
        <div className="mt-7 flex flex-wrap justify-start items-center">
          {tactics &&
            tactics?.map((item, i) => (
              <Link
                className="group my-2 sm:mr-9 inline-block text-transparent bg-clip-text bg-gradient-to-r from-blue-1 to-purple-1 font-semibold text-xl hover:bg-gradient-to-r hover:from-purple-1 hover:to-blue-1"
                key={`${item.text}-${i}`}
                to={item.route}
              >
                {item.text}{" "}
                <RightArrow className="ml-1 inline-block mb-1 text-indigo-1 group-hover:text-blue-1 group-hover:scale-120 group-hover:translate-x-1 ease-in duration-150" />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tactics;
