import React, { useEffect, createRef } from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import PieChart from "../../../images/amazon/pie-chart.svg";
import { Link } from "gatsby";
import cn from "classnames";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { useSegment } from "../../../utils/analytics";
import { Category, Name, Type } from "../../../utils/analytics/constants";
import { nav } from "../../../utils/navigation";
import { runAnimation } from "../../../utils/animations";

interface Strategy {
  title: any;
  gradientPosition: number;
  tabs: any;
  cta: any;
}

const Strategy: React.FC<Strategy> = props => {
  const { title, gradientPosition, tabs, cta } = props;
  const { helpers } = useSegment();
  const [currentSelection, setCurrentSelection] = React.useState(
    tabs ? tabs[0] : null
  );

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    if (currentSelection?.animationJson?.internal?.content) {
      const anim = runAnimation(
        animationContainer.current,
        JSON.parse(currentSelection?.animationJson?.internal?.content),
        true
      );
      return () => {
        anim?.destroy();
      };
    }
  }, [currentSelection]);

  return (
    <div className="relative items-center">
      <div className="relative z-10">
        <GradientTitle
          className="text-center max-w-2xl mx-auto"
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h2}
          lineBreak={false}
          title={title}
        />
      </div>
      <div className="grid grid-cols-4 items-center justify-evenly max-w-3xl lg:max-w-5xl mx-auto mt-14 ">
        {tabs &&
          tabs?.map(item => (
            <div
              key={item.key}
              className="group col-span-2 mb-6 flex justify-center md:mb-0 lg:col-span-1"
            >
              <button
                className={cn(
                  "bg-none text-gray-2 hover:text-indigo-1 flex justify-center items-center focus:outline-none",
                  currentSelection.selector === item.selector && "text-indigo-1"
                )}
                onClick={() => setCurrentSelection(item)}
              >
                <span
                  className={cn(
                    "bg-none w-[60px] h-[60px] mr-2 flex items-center justify-center rounded-full ease-in duration-200 group-hover:shadow-2xl",
                    currentSelection.selector === item.selector && "shadow-2xl"
                  )}
                >
                  <PieChart fill="current" className="fill-current" />
                </span>
                <span className={"text-left max-w-[150px]"}>
                  {item.selector}
                </span>
              </button>
            </div>
          ))}
        {currentSelection && (
          <div className="w-full text-center col-span-4 mt-4 md:mt-18">
            <Header
              variant={HeaderVariant.h3}
              className="text-3xl text-indigo-1"
            >
              {currentSelection?.title}
            </Header>
            <Paragraph className="py-6 md:px-12">
              {currentSelection?.description?.description}
            </Paragraph>
            {currentSelection.cta && currentSelection?.ctaLink && (
              <Link
                className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-1 to-purple-1 font-semibold text-xl hover:bg-gradient-to-r hover:from-purple-1 hover:to-blue-1"
                to={currentSelection?.ctaLink}
              >
                {currentSelection.cta} →
              </Link>
            )}
            <div className="min-h-[350px] md:min-h-[500px] flex items-center">
              <div
                className="w-full mx-auto max-w-xl"
                ref={animationContainer}
              ></div>
            </div>
            {cta && (
              <div className="flex justify-center">
                <Button
                  id="strategy-cta"
                  className=""
                  variant={ButtonVariant.primary}
                  size={ButtonSize.large}
                  text={cta?.text}
                  onClick={() => {
                    helpers
                      .delayTrack(Name.Cta, {
                        category: Category.ButtonClick,
                        type: Type.Button,
                        text: cta?.text,
                        page_url: location.href,
                        destination: cta?.route,
                        location: "Strategy Section"
                      })
                      .then(() => {
                        nav(cta?.route);
                      });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Strategy;
