import React, { createRef, useEffect } from "react";
import lottie from "lottie-web";
import { HeaderVariant } from "../../Header";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import pulsing from "../../../animations/amazon/pulsing.json";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroCtas from "../../HeroCtas";

interface HeroSectionProps {
  h1Title: string;
  title: [];
  description: string;
  image: any;
  gradientPosition: number;
  secondaryCta?: any;
  cta?: any;
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const { title, description, image, gradientPosition, cta, secondaryCta } =
    props;

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: pulsing
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <>
      <NavigationBarSpacer />
      <div className="grid grid-cols-12 items-center space-x-2">
        <div className="col-span-12 lg:col-span-6 pt-12 lg:pt-28">
          <GradientTitle
            gradientPosition={gradientPosition}
            className="text-center w-full lg:text-left lg:w-2/3 lg:text-6xl"
            color="orange"
            variant={HeaderVariant.h1}
            title={title}
            lineBreak={false}
          />
          <Paragraph className="text-center lg:text-left">
            {description}
          </Paragraph>
          <HeroCtas cta={cta} secondaryCta={secondaryCta} />
        </div>
        <div className="col-span-12 pt-20 lg:pt-0 lg:col-span-6 h-full flex items-center justify-start relative">
          {/* Placeholder waiting for pulsing */}
          <div className="flex justify-center lg:absolute top-1/4 w-full">
            <div
              className="hidden lg:block absolute top-10 left-12 lg:top-8 lg:left-9 xl:top-10 xl:left-13 -translate-x-1/2 -translate-y-1/2 w-96 h-96"
              ref={animationContainer}
            ></div>
            <GatsbyImage
              image={image}
              alt="Hero Image"
              className="w-full lg:absolute top-0 max-w-2xl"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
